import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/10B/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/10B/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/10B/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/10B/4.jpg';
import p5 from 'assests/Photos/ClassAssembly/2023/10B/5.jpg';
import p6 from 'assests/Photos/ClassAssembly/2023/10B/6.jpg';
import p7 from 'assests/Photos/ClassAssembly/2023/10B/7.jpg';
import p8 from 'assests/Photos/ClassAssembly/2023/10B/8.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly10B2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      'Immunity'
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 10B  Date: 4 JULY 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of class 10 B of National Public School, Yeshwanthpur presented their class assembly on the topic 
        "Immunity”. It aimed at showcasing the wonderful mechanism in our body that springs into action as soon as foreign 
        species invade the body. The topic was presented in the form of a skit with a dance mimicking a war documentary, 
        drawing parallels between a war and the immune system fighting pathogens, making it more relatable and easier to 
        understand. The duration of the skit was for few minutes but it was conveyed in an effective manner. The conclusion 
        of the assembly raised awareness emphasized the importance of a strong immune system and the need to take preventive 
        measures to protect it. 

 {/* <br></br>
 The students not only learnt something completely new in the form of Indian Sign Language, but also effectively 
 communicated the significance of inclusivity and the necessary measures to be taken to an attentive audience.  */}
        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Protect Your Body with a Strong Immune System”

<br/>
        </Typography>
        <br></br>
        <Typography
        variant={'h6'}
        align={'center'}
        >
       <u> The Tiny Soldiers in Our Body</u>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 150}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly10B2023;